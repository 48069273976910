import { defineStore } from 'pinia'
import visionHomeQuery from '../apollo/queries/pages/vision/home.gql'
import visionNewsEventsQuery from '../apollo/queries/pages/vision/newsEvents.gql'
import visionLeadershipQuery from '../apollo/queries/pages/vision/leadership.gql'

export const useVisionStore = defineStore('vision', {
  state: () => ({
    pages: {
      home: {},
      newsEvents: {},
      leadership: {}
    }
  }),
  getters: {
    getVisionHome: (state) => {
      return state.pages.home ? state.pages.home.vision.data.attributes : {}
    },
    getVisionNewsEvents: (state) => {
      return state.pages.newsEvents ? state.pages.newsEvents.visionNewsEvent.data.attributes : {}
    },
    getVisionLeadership: (state) => {
      return state.pages.leadership ? state.pages.leadership.visionLeadership.data.attributes : {}
    }
  },
  actions: {
    async requestVisionHomeData() {
      if (Object.keys(this.pages.home).length !== 0) return

      const { data } = await useAsyncQuery(visionHomeQuery) // eslint-disable-line no-undef

      if (data) this.pages.home = data
    },
    async requestVisionNewsEventsData() {
      if (Object.keys(this.pages.newsEvents).length !== 0) return

      const { data } = await useAsyncQuery(visionNewsEventsQuery) // eslint-disable-line no-undef

      if (data) this.pages.newsEvents = data
    },
    async requestVisionLeadershipData() {
      if (Object.keys(this.pages.leadership).length !== 0) return

      const { data } = await useAsyncQuery(visionLeadershipQuery) // eslint-disable-line no-undef

      if (data) this.pages.leadership = data
    }
  }
})
